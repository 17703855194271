// Taabi colors

// primary

$light-primary: #199bd5;
$light-primary-contrast: #ffffff;

$dark-primary: #199bd5;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #f5881f;
$light-accent-contrast: #000000;

$dark-accent: #f5881f;
$dark-accent-contrast: #000000;

// common

$page-background: #199bd52f;
